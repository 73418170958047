var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(9, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "12", sm: "6", lg: "3" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "mt-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c(
            "form-data",
            {
              ref: "technicalreportReference",
              attrs: { references: _vm.formReferences, model: _vm.modelObj },
              on: {
                "update:references": function ($event) {
                  _vm.formReferences = $event
                },
              },
            },
            [
              _vm.modelObj.id && _vm.listOfDocuments.length > 0
                ? _c(
                    "template",
                    { slot: "technicalreport_documents" },
                    [
                      _c("documents", {
                        attrs: {
                          documents: _vm.listOfDocuments,
                          deleteIds: _vm.deleteDocumentIds,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }